import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import logo from "../images/skillmeter_logo_blue.png";
import Cookies from 'js-cookie';


export default function Header() {
    const [clicked, setClicked] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect( async () => {
      //return JSON.parse(localStorage.getItem('user'));;

      if (Cookies.get('skillmeterUser')) {
        //console.log('found cookie')
        const cookie = JSON.parse(Cookies.get('skillmeterUser'));
        //console.log(cookie)
        if (cookie) setLoggedIn(true);
      }
      else {
        //console.log('didnt find cookie')
      }
    }, []);



    

    return (
        <header className="relative py-4 md:py-6" x-data="{expanded: false}">
        <div className="container px-4 mx-auto sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <Link to="/" title="" className="flex rounded outline-none focus:outline-none">
                <img className="w-auto h-8" src={logo} alt="Skillmeter" />
              </Link>
            </div>
  
            <div className="flex lg:hidden">
              <button type="button" className="text-gray-900" onClick={()=>{setClicked(!clicked)}} aria-expanded="expanded">
                <span className={clicked? 'hidden':'block'} aria-hidden="true">
                  <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </span>
  
                <span className={clicked? 'block':'hidden'} aria-hidden="true">
                  <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </span>
              </button>
            </div>
  
            <div className="hidden lg:flex lg:ml-10 xl:ml-16 lg:items-center lg:justify-center lg:space-x-8 xl:space-x-16">
              <Link to="/" title="" className="text-base font-medium text-gray-900 rounded focus:outline-none hover:text-opacity-50">
                Home
              </Link>
  
              <Link to="/features" title="" className="text-base font-medium text-gray-900 rounded focus:outline-none hover:text-opacity-50">
                Features
              </Link>
  
              <Link to="/pricing" title="" className="text-base font-medium text-gray-900 rounded focus:outline-none hover:text-opacity-50">
                Pricing
              </Link>
  
              <Link to="/contact" title="" className="text-base font-medium text-gray-900 rounded focus:outline-none hover:text-opacity-50">
                Contact
              </Link>
            </div>
  
            <div className="hidden lg:ml-auto lg:flex lg:items-center lg:space-x-8 xl:space-x-10">
  
              {loggedIn ? 
              <a href={process.env.DASHBOARD_URL} title="" className="inline-flex items-center justify-center px-5 py-2 text-base font-bold leading-7 text-white bg-smcolor border border-transparent rounded-xl hover:bg-smcolor-dark focus:outline-none" role="button">
              My dashboard
            </a>
              :
              <a href={process.env.SIGNIN_URL} title="" className="inline-flex items-center justify-center px-5 py-2 text-base font-bold leading-7 text-white bg-smcolor border border-transparent rounded-xl hover:bg-smcolor-dark focus:outline-none" role="button">
                Sign in
              </a>
              }
            </div>
          </div>
  
  
          <nav className={clicked? 'block':'hidden'}>
            <div className="px-1 py-8">
              <div className="grid gap-y-7">
                <Link to="/" title="" className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-xl hover:bg-gray-50 focus:outline-none ">
                  Home
                </Link>
  
                <Link to="/features" title="" className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-xl hover:bg-gray-50 focus:outline-none ">
                  Features
                </Link>
  
                <Link to="/pricing" title="" className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-xl hover:bg-gray-50 focus:outline-none ">
                  Pricing
                </Link>
  
                <Link to="/contact" title="" className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-xl hover:bg-gray-50 focus:outline-none ">
                  Contact
                </Link>
  
                {loggedIn ? 
              <a href={process.env.DASHBOARD_URL} title="" className="inline-flex items-center justify-center px-5 py-2 text-base font-bold leading-7 text-white bg-smcolor border border-transparent rounded-xl hover:bg-smcolor-dark focus:outline-none" role="button">
              My dashboard
            </a>
              :
                <a href={process.env.SIGNIN_URL} title="" className="inline-flex items-center justify-center px-5 py-2 text-base font-bold leading-7 text-white  bg-smcolor border border-transparent rounded-xl hover:bg-smcolor-dark focus:outline-none " role="button">
                Sign in
              </a>
              }

              </div>
            </div>
          </nav>
          
  
        </div>
      </header>
  

    );
}